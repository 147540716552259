import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import caret from "../icons/CaretRight.svg"
import * as styles from "./announcement-page.module.css"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const AnnouncementPage = ({ data, location }) => {
  const post = data.contentfulAnnouncement
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const Bold = ({ children }) => <span style={{fontWeight: "600"}}>{children}</span>
  const Text = ({ children }) => <p>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (post, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: post => {
        return (
          <>
            <pre>
              <code>{JSON.stringify(post, null, 2)}</code>
            </pre>
          </>
        )
      },
    },
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
      />
      <article
        className="section-wrapper page-main"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Link to="/announcements" className={styles.back_link} >
            <button className={styles.back_button}>
                <img src={caret} alt="" className={styles.back_caret}/>
                All announcements
            </button>
        </Link>
        
        <div className={styles.announcement_content}>
          <header cla>
            <h1 itemProp="headline" className={styles.announcement_title}>{post.title}</h1>
            <p className={styles.announcement_subtitle}>{post.date}   ·  {post.location}</p>
          </header>
          <div>{renderRichText(post.body, options)}</div>
        </div>
      </article>
    </Layout>
  )
}

export default AnnouncementPage

export const pageQuery = graphql`
  query AnnouncementBySlug(
    $slug: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAnnouncement( slug: { eq: $slug } ) {
      slug
      title
      date(formatString: "MMMM Do, YYYY")
      location
      body{
        raw
      }
    }
  }
`
